//
// footer.scss
//

// Payment

.footer-payment {
  height: 1.5rem;
  width: auto;
}
