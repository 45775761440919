//
// embed.scss
//

.embed-responsive-item-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
